@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
@font-face {
  font-family: 'icomoon';
  src: url("/public/fonts/icomoon.eot?5six6t");
  src: url("/public/fonts/icomoon.eot?5six6t#iefix") format('embedded-opentype'), url("/public/fonts/icomoon.ttf?5six6t") format('truetype'), url("/public/fonts/icomoon.woff?5six6t") format('woff'), url("/public/fonts/icomoon.svg?5six6t#icomoon") format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  height: 32px;
  width: 32px;
  font-size: 32px;
  line-height: 1;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-link-out,
.icon-link-to {
  font-size: 16px;
  height: 16px;
  width: 16px;
  position: relative;
  display: block;
}
.icon-link-out:before,
.icon-link-to:before,
.icon-link-out:after,
.icon-link-to:after {
  position: absolute;
  top: 0;
  left: 0;
}
.icon-link-to {
  margin: 0 8px 0 4px;
  color: #abbeca;
  -webkit-transition: margin 300ms ease-out;
  transition: margin 300ms ease-out;
}
.icon-link-to:before {
  content: "\e916";
}
.icon-link-to:after {
  content: "\e915";
  -webkit-transition: left 300ms ease-out;
  transition: left 300ms ease-out;
}
body a:hover .icon-link-to {
  margin: 0 4px 0 8px;
  color: #f0638a;
}
body a:hover .icon-link-to:after {
  left: 2px;
}
.icon-link-out:before {
  content: "\e918";
}
.icon-link-out:after {
  content: "\e917";
}
.icon-mobile:before {
  content: "\e911";
}
.icon-code:before {
  content: "\e910";
}
.icon-lightning:before {
  content: "\e90d";
}
.icon-dislike:before {
  content: "\e90e";
}
.icon-ten:before {
  content: "\e90f";
}
.icon-journey:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e908";
}
.icon-flow:before {
  content: "\e909";
}
.icon-edit:before {
  content: "\e906";
}
.icon-chat:before {
  content: "\e907";
}
.icon-like:before {
  content: "\e90a";
}
.icon-like-dislike:before {
  content: "\e90b";
}
.icon-timer:before {
  content: "\e903";
}
.icon-star:before {
  content: "\e904";
}
.icon-users:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e912";
}
.icon-envelope:before {
  content: "\e913";
}
.icon-in:before {
  content: "\e914";
}
.icon-user:before {
  content: "\e900";
}
.icon-hashtag:before {
  content: "\e901";
}
.icon-home:before {
  content: "\e902";
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  overflow-y: scroll;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  color: #fff;
  padding-top: 74px;
  scrollbar-width: thin;
  scrollbar-color: rgba(244,249,252,0.2) rgba(44,55,73,0.2);
}
body a {
  color: #000;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}
body a:hover {
  color: #f0638a;
  text-decoration: underline;
}
body,
body * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(44,55,73,0.2);
}
::-webkit-scrollbar-track {
  background-color: rgba(44,55,73,0.1);
}
.mainFrame {
  background-color: #fff;
  color: #2c3749;
}
.mainFrame h1,
.mainFrame h2 {
  color: #2c3749;
  max-width: 1024px;
  text-align: left;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.mainFrame h1 {
  padding-left: 24px;
  padding-right: 24px;
}
.mainFrame nav a {
  color: #000;
}
.mainFrame nav a.active {
  color: #f0638a;
}
.mainFrame nav a:hover {
  text-decoration: none;
}
h1 {
  font-weight: 100;
  font-size: 46px;
  font-size: 3em;
  line-height: 1.1em;
  margin: 32px 0 16px;
  -webkit-animation: loadH1 600ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadH1 600ms cubic-bezier(0, 0, 0, 0.94);
}
h2 {
  font-size: 1.7em;
  line-height: 1.1em;
  margin: 16px 0 16px;
}
h3 {
  font-size: 1.4em;
  line-height: 1.3em;
  margin: 0 0 8px;
}
h2,
h3 {
  font-weight: 300;
}
p {
  margin: 0 0 16px;
  padding: 0;
}
p.large {
  margin-bottom: 16px;
  font-size: 1.3em;
  font-weight: 300;
}
nav {
  padding: 48px 0 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation: loadNav 800ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadNav 800ms cubic-bezier(0, 0, 0, 0.94);
}
nav >div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 1024px;
  padding: 0 24px;
}
nav a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 32px;
  width: 32px;
  margin-right: 16px;
}
nav a:last-child {
  margin-right: 0;
}
nav .left {
  padding-right: 32px;
}
@media screen and (max-width: 1024px) {
  nav >div {
    padding: 0 40px;
  }
}
.homeCover {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 24px;
  text-align: left;
  -webkit-animation: loadHomeCover 800ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadHomeCover 800ms cubic-bezier(0, 0, 0, 0.94);
}
.homeCover a {
  text-decoration: underline;
}
.homeCover h1 {
  padding: 0;
}
.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 16px;
  text-align: center;
}
.pjTrick + .mainFrame .content {
  padding: 16px 0;
}
.page,
.itemList,
.projectWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page {
  overflow: hidden;
}
.diamond,
.designThinking {
  position: fixed;
  z-index: 9;
  top: 164px;
  left: 16px;
  padding: 16px;
  margin-left: auto;
  -webkit-animation: loadMenu 1600ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadMenu 1600ms cubic-bezier(0, 0, 0, 0.94);
}
@media screen and (max-width: 1024px) {
  .diamond,
  .designThinking {
    display: none;
  }
}
.designThinking .phase {
  height: 60px;
  width: 60px;
  background-image: url("/public/imgs/design-thinking-phase.png");
  background-size: cover;
  background-position: center;
  margin-top: -24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  filter: brightness(180%) grayscale(100%);
  -webkit-filter: brightness(180%) grayscale(100%);
  -moz-filter: brightness(180%) grayscale(100%);
  -o-filter: brightness(180%) grayscale(100%);
  -ms-filter: brightness(180%) grayscale(100%);
  -webkit-transition: -webkit-filter 300ms ease-out;
  transition: -webkit-filter 300ms ease-out;
  transition: filter 300ms ease-out;
  transition: filter 300ms ease-out, -webkit-filter 300ms ease-out;
  font-weight: 400;
  font-size: 0.8em;
  color: #495e6c;
}
.designThinking .phase:first-child {
  margin-top: 0;
}
.designThinking .phase:nth-child(2) {
  margin-left: 20px;
}
.designThinking .phase.active {
  color: #f0638a;
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
}
.designThinking div:nth-child(4) {
  margin-top: -24px;
  margin-left: 20px;
}
.designThinking div:nth-child(4).active .phase {
  color: #f0638a;
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
}
.diamond >div {
  font-weight: 500;
  font-size: 0.7em;
  line-height: 1.5em;
  color: #495e6c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  height: 30px;
  width: 60px;
  background-size: cover;
  filter: brightness(180%) grayscale(100%);
  -webkit-filter: brightness(180%) grayscale(100%);
  -moz-filter: brightness(180%) grayscale(100%);
  -o-filter: brightness(180%) grayscale(100%);
  -ms-filter: brightness(180%) grayscale(100%);
  -webkit-transition: -webkit-filter 300ms ease-out;
  transition: -webkit-filter 300ms ease-out;
  transition: filter 300ms ease-out;
  transition: filter 300ms ease-out, -webkit-filter 300ms ease-out;
}
.diamond >div:first-child {
  background-image: url("/public/imgs/diamond-1.png");
}
.diamond >div:nth-child(2) {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-image: url("/public/imgs/diamond-2.png");
}
.diamond >div:nth-child(3) {
  background-image: url("/public/imgs/diamond-3.png");
}
.diamond >div:nth-child(4) {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-image: url("/public/imgs/diamond-4.png");
}
.diamond >div.active {
  color: #f0638a;
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
}
.itemList {
  overflow: auto;
  background-color: #fff;
  padding: 16px;
  text-align: left;
  margin: 32px auto 8px;
  width: 100%;
  max-width: 1024px;
  color: #abbeca;
}
.listHead {
  padding: 8px;
  margin-bottom: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: thin solid #495e6c;
  -webkit-animation: loadListHead 800ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadListHead 800ms cubic-bezier(0, 0, 0, 0.94);
}
.listHead h2 {
  width: auto;
  margin-left: 0;
}
.listHead .tag {
  margin-left: 16px;
  margin-bottom: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
.item {
  width: 33.333%;
  margin: 0 0 32px;
  padding: 0 8px;
  overflow: hidden;
  -webkit-transition: background 300ms ease-out;
  transition: background 300ms ease-out;
  -webkit-animation: loadItem 800ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadItem 800ms cubic-bezier(0, 0, 0, 0.94);
}
.item >a {
  display: block;
  background-color: #f0638a;
}
.item img {
  width: 100%;
  height: 144px;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
  -webkit-transition: opacity 300ms ease-out;
  transition: opacity 300ms ease-out;
  opacity: 1;
}
.item .item-info {
  padding: 16px 0;
}
.item .item-info h3 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}
.item .item-info h3:before {
  content: '///';
  margin-right: 4px;
  letter-spacing: 0.08em;
  display: block;
  width: 20px;
  text-align: right;
}
.item .item-info p.large {
  color: #495e6c;
  font-size: 1.1em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item .item-info p.large:before {
  content: '////';
  margin-right: 4px;
  letter-spacing: 0.08em;
  display: block;
  width: 20px;
  text-align: right;
}
.item .item-info a {
  text-decoration: none;
  display: block;
}
.item .item-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
.item:hover {
  background-color: #fff;
}
.item:hover .item-info a {
  text-decoration: none;
}
.item:hover .item-info a h3 {
  text-decoration: none;
  color: #f0638a;
}
.item:hover .item-info a p.large {
  text-decoration: none;
}
.item:hover img {
  opacity: 0.5;
}
@media screen and (max-width: 870px) {
  .item {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .item {
    width: 100%;
  }
}
.tag {
  border: 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #495e6c;
  margin-right: 6px;
  margin-bottom: 8px;
  padding: 0px 8px;
  border-radius: 24px;
  word-break: keep-all;
  font-size: 0.8em;
  text-decoration: none;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
}
.tag .fa-solid {
  margin-right: 6px;
}
.tag.large {
  text-decoration: none;
  font-size: 1em;
  padding: 4px 16px;
}
.tag.large:hover {
  color: #f0638a;
}
.tag.large .fa-solid {
  font-size: 0.8em;
  margin-right: 8px;
}
.tag.large .fa-close {
  margin-left: 8px;
  margin-right: 0;
  color: #f0638a;
}
.projectWrapper {
  background-color: #fff;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-animation: loadPj 1200ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadPj 1200ms cubic-bezier(0, 0, 0, 0.94);
}
.projectWrapper .pjHead {
  width: 100%;
  max-width: 100%;
  padding: 0 32px 32px;
}
.projectWrapper .pjHead h1,
.projectWrapper .pjHead p.large {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
}
.projectWrapper .pjHead h1:before,
.projectWrapper .pjHead p.large:before {
  display: block;
  width: 44px;
}
.projectWrapper .pjHead h1 {
  font-weight: 300;
  margin-top: 16px;
  font-size: 40px;
  padding-left: 0;
  padding-right: 0;
  -webkit-animation: none;
          animation: none;
}
.projectWrapper .pjHead h1:before {
  content: '///';
}
.projectWrapper .pjHead p.large {
  font-weight: 500;
  color: inherit;
  font-size: 18px;
  letter-spacing: 0.05em;
  margin: 0 auto;
}
.projectWrapper .pjHead p.large:before {
  content: '/////';
  letter-spacing: 0.1em;
}
.projectWrapper .pjBody a {
  text-decoration: underline;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.projectWrapper .pjBody a.tag {
  text-decoration: none;
}
.projectWrapper .pjBody a .icon-link-out {
  margin: 0 8px 0 4px;
  color: #abbeca;
  -webkit-transition: margin 300ms ease-out;
  transition: margin 300ms ease-out;
}
.projectWrapper .pjBody a .icon-link-out:after {
  -webkit-transition: left 300ms ease-out, top 300ms ease-out;
  transition: left 300ms ease-out, top 300ms ease-out;
}
.projectWrapper .pjBody a:hover .icon-link-out {
  margin: 0 4px 0 8px;
  color: #f0638a;
}
.projectWrapper .pjBody a:hover .icon-link-out:after {
  top: -2px;
  left: 2px;
}
.projectWrapper .pjBody b,
.projectWrapper .pjBody strong {
  font-weight: 600;
}
.projectWrapper h1,
.projectWrapper h2,
.projectWrapper h3,
.projectWrapper h4,
.projectWrapper ul {
  width: 100%;
  max-width: 780px;
  margin: 0 auto 8px;
  text-align: left;
}
.projectWrapper h2 {
  margin-top: 16px;
  margin-bottom: 32px;
}
.projectWrapper p,
.projectWrapper ul {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.7em;
}
.projectWrapper p {
  width: 100%;
  max-width: 780px;
  margin: 0 auto 8px;
  text-align: left;
}
.projectWrapper p.caption {
  margin-bottom: 32px;
  text-align: center;
  color: #8097a6;
  font-size: 0.85em;
  letter-spacing: 0.06em;
  text-align: center;
}
.projectWrapper p.large {
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 32px auto 40px;
  color: #f0638a;
}
.projectWrapper .block {
  overflow: hidden;
  height: auto;
  width: 100%;
  max-width: 100%;
  padding: 48px 32px;
}
.projectWrapper .block >div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
.projectWrapper .block >div.quote {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}
.projectWrapper .block >div.quote h4,
.projectWrapper .block >div.quote p.large {
  text-align: left;
}
.projectWrapper .block >div.quote h4 {
  color: #8097a6;
}
.projectWrapper .block >div.quote p.large {
  margin-top: 0;
  font-size: 2em;
  line-height: 1.2em;
  color: #cd869a;
  font-style: italic;
}
.projectWrapper .block >div >img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  height: 100%;
}
.projectWrapper .block >div >img.margin {
  margin-right: auto;
  margin-left: auto;
}
.projectWrapper .block >div.bottom {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.projectWrapper .block >div.three >div {
  width: 33.333%;
}
@media screen and (max-width: 800px) {
  .projectWrapper .block >div.three >div {
    width: 100%;
  }
}
.projectWrapper .block >div.four >div {
  width: 25%;
}
@media screen and (max-width: 800px) {
  .projectWrapper .block >div.four >div {
    width: 100%;
  }
}
.projectWrapper .block >div >div {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 24px 0;
}
@media screen and (max-width: 800px) {
  .projectWrapper .block >div >div {
    width: 100%;
  }
}
.projectWrapper .block >div h4 {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 14px;
  margin-top: 16px;
  line-height: 1.5em;
}
.projectWrapper .block >div p,
.projectWrapper .block >div p.large,
.projectWrapper .block >div h2,
.projectWrapper .block >div h3,
.projectWrapper .block >div h4 {
  text-align: center;
  color: inherit;
}
.projectWrapper .block >div [class^="icon-"],
.projectWrapper .block >div [class*=" icon-"] {
  font-size: 80px;
  border: 1px solid #d3dfe6;
  border-radius: 50%;
}
.projectWrapper .block:nth-child(even) {
  background-color: #f4f9fc;
}
.projectWrapper img,
.projectWrapper video {
  display: block;
  max-width: 100%;
  margin: 32px auto 16px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
          box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
.projectWrapper img.cover,
.projectWrapper video.cover {
  width: 100%;
  height: 264px;
  margin: 16px 0 0;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0;
  -webkit-animation: loadCover 1200ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadCover 1200ms cubic-bezier(0, 0, 0, 0.94);
}
.projectWrapper img.cover,
.projectWrapper video.cover,
.projectWrapper img.white,
.projectWrapper video.white {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  width: 100%;
  max-width: 1024px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 48px auto;
}
body a.contact {
  font-size: 1.6em;
  margin-bottom: 16px;
  font-weight: 300;
  color: #000;
  text-decoration: none;
  -webkit-transition: opacity 300ms ease-out, color 300ms ease-out;
  transition: opacity 300ms ease-out, color 300ms ease-out;
  max-width: 1024px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: loadContact 800ms cubic-bezier(0, 0, 0, 0.94);
          animation: loadContact 800ms cubic-bezier(0, 0, 0, 0.94);
}
h1 + body a.contact {
  margin-top: 32px;
}
body a.contact [class^="icon-"],
body a.contact [class*=" icon-"] {
  margin-right: 16px;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 300ms ease-out;
  transition: color 300ms ease-out;
}
body a.contact b {
  font-weight: 300;
}
body a.contact span {
  opacity: 0.5;
}
body a.contact:hover {
  color: #f0638a;
}
body a.contact:hover b {
  text-decoration: underline;
}
body a.contact:hover [class^="icon-"],
body a.contact:hover [class*=" icon-"] {
  color: #f0638a;
  text-decoration: none;
}
.pjTags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 80px 32px;
}
@-webkit-keyframes loadH1 {
  0% {
    margin-top: 64px;
    margin-bottom: 32px;
    opacity: 0;
  }
  100% {
    margin-top: 32px;
    margin-bottom: 16px;
    opacity: 1;
  }
}
@keyframes loadH1 {
  0% {
    margin-top: 64px;
    margin-bottom: 32px;
    opacity: 0;
  }
  100% {
    margin-top: 32px;
    margin-bottom: 16px;
    opacity: 1;
  }
}
@-webkit-keyframes loadNav {
  from {
    padding-top: 0px;
    opacity: 0;
  }
  to {
    padding-top: 48px;
    opacity: 1;
  }
}
@keyframes loadNav {
  from {
    padding-top: 0px;
    opacity: 0;
  }
  to {
    padding-top: 48px;
    opacity: 1;
  }
}
@-webkit-keyframes loadHomeCover {
  0% {
    padding-top: 32px;
    opacity: 0;
  }
  100% {
    padding-top: 0;
    opacity: 1;
  }
}
@keyframes loadHomeCover {
  0% {
    padding-top: 32px;
    opacity: 0;
  }
  100% {
    padding-top: 0;
    opacity: 1;
  }
}
@-webkit-keyframes loadMenu {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loadMenu {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes loadListHead {
  0% {
    padding-top: 48px;
    opacity: 0;
  }
  30% {
    padding-top: 48px;
    opacity: 0;
  }
  100% {
    padding-top: 8px;
    opacity: 1;
  }
}
@keyframes loadListHead {
  0% {
    padding-top: 48px;
    opacity: 0;
  }
  30% {
    padding-top: 48px;
    opacity: 0;
  }
  100% {
    padding-top: 8px;
    opacity: 1;
  }
}
@-webkit-keyframes loadItem {
  0% {
    margin: 48px 0;
    opacity: 0;
  }
  100% {
    margin: 0 0 32px;
    opacity: 1;
  }
}
@keyframes loadItem {
  0% {
    margin: 48px 0;
    opacity: 0;
  }
  100% {
    margin: 0 0 32px;
    opacity: 1;
  }
}
@-webkit-keyframes loadPj {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loadPj {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes loadCover {
  0% {
    height: 364px;
    opacity: 0;
  }
  100% {
    height: 264px;
    opacity: 1;
  }
}
@keyframes loadCover {
  0% {
    height: 364px;
    opacity: 0;
  }
  100% {
    height: 264px;
    opacity: 1;
  }
}
@-webkit-keyframes loadContact {
  0% {
    margin-top: 16px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes loadContact {
  0% {
    margin-top: 16px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
